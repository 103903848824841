// 登录模块
import axios from 'axios'
import QS from 'qs'
// 登录
export const login = params => {
    return axios.post(`/api/login`, QS.stringify(params)).then(res => res.data)
}
// 退出登录
export const ClearLogin = params => {
    return axios.get(`/api/exit`).then(res => res.data)
}

// 修改密码
export const setPassword = params => {
    return axios.post(`/api/setPassword`, QS.stringify(params)).then(res => res.data)
}