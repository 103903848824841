import Vue from 'vue'
import Vuex from 'vuex'
import tabMenu from './tabMenu'

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        tabMenu
    }
})
