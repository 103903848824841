// 点击左侧实现动态路由

export default {
    state: {
        menuList: JSON.parse(window.sessionStorage.getItem('tabs')) || [{ // 动态左侧点击导航
            name: "首页",
            path: '/index'
        }],
    },
    mutations: {
        // 点击左侧添加动态导航
        changeMenu(state, val) {
            if (val.name !== 'home') {
                const result = state.menuList.findIndex(item => item.name === val.name)
                if (result === -1) {
                    state.menuList.push(val)
                    window.sessionStorage.setItem('tabs', JSON.stringify(state.menuList))
                }
            }
        },
        // 删除动态导航
        removeMenu(state, val) {
            const index = state.menuList.findIndex(item => item.name === val.name)
            // 删除数组中的某个元素
            state.menuList.splice(index, 1)
            window.sessionStorage.setItem('tabs', JSON.stringify(state.menuList))
        },
        // 初始面包屑的内容
        initMenu(state) {
            window.sessionStorage.clear()
            state.menuList = [{name: "首页", path: '/index'}]
        }
    }
}
