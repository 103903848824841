import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from './store'
import axios from 'axios'
import moment from 'moment'
import ElementUI from 'element-ui'
// 引入全局样式
import "@/styles/global.css"
import '@/styles/transform.css'
import 'nprogress/nprogress.css'
// 引入echarts
import "@/utils/echarts.js"
import "default-passive-events"


Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(ElementUI)

axios.interceptors.request.use(request => {
    let url = 'https://wx.qzgjwh.com/live/msg/send'
    if (request.url.indexOf(url) > -1) {
        request.headers['Content-Type'] = 'application/json'
    } else {
        request.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    return request
})

axios.interceptors.response.use(
    response => {
        if (response.status === 500) {
            ElementUI.Message({
                message: '系统异常',
                type: 'error'
            })
            return Promise.reject(new Error('系统异常'))
        }
        if (response.data.code === 401) {
            ElementUI.Message({
                message: response.data.msg,
                type: 'error'
            })
            router.push('/login')
            return Promise.reject(new Error(response.data.msg))
        }
        if (response.data.code === 500) {
            ElementUI.Message({
                message: response.data.msg,
                type: 'error'
            })
            return Promise.reject(new Error(response.data.msg))
        }
        return response
    },
    error => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    ElementUI.Message({
                        message: '错误请求',
                        type: 'error'
                    })
                    break
                case 401:
                    ElementUI.Message({
                        message: '未授权，请重新登录',
                        type: 'error'
                    })
                    break
                case 403:
                    ElementUI.Message({
                        message: '请求超时',
                        type: 'error'
                    })
                    error.message = '拒绝访问'
                    break
                case 404:
                    ElementUI.Message({
                        message: '请求错误，未找到该资源',
                        type: 'error'
                    })
                    break
                case 405:
                    ElementUI.Message({
                        message: '请求方法未允许',
                        type: 'error'
                    })
                    break
                case 408:
                    ElementUI.Message({
                        message: '请求超时',
                        type: 'error'
                    })
                    break
                case 500:
                    ElementUI.Message({
                        message: '服务器异常',
                        type: 'error'
                    })
                    break
                case 501:
                    ElementUI.Message({
                        message: '网络未实现',
                        type: 'error'
                    })
                    break
                case 502:
                    ElementUI.Message({
                        message: '网路错误',
                        type: 'error'
                    })
                    break
                case 503:
                    ElementUI.Message({
                        message: '服务不可用',
                        type: 'error'
                    })
                    break
                case 504:
                    ElementUI.Message({
                        message: '网络超时',
                        type: 'error'
                    })
                    break
                case 505:
                    ElementUI.Message({
                        message: 'http版本不支持该请求',
                        type: 'error'
                    })
                    break
                default:
                    ElementUI.Message({
                        message: `连接错误${error.response.status}`,
                        type: 'error'
                    })
            }
        } else {
            ElementUI.Message({
                message: '网络出现问题，请稍后再试',
                type: 'error'
            })
        }
    }
)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")
