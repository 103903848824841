import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import axios from "axios";
import NProgress from 'nprogress'

// 解决手动输入首页报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [{
    path: '/',
    redirect: '/home'
}, {
    path: '/login',
    name: 'login',
    component: () => import("@/views/Login.vue")
}, {
    name: "home",
    path: '/home',
    component: Home,
    redirect: '/index', // 重定向
    meta: {title: '首页'},
    children: [ // 子路由
        {
            name: "index",
            path: '/index',
            meta: {title: '首页'},
            component: () => import('@/views/index.vue'),
        },
        {
            name: "menu",
            path: '/system/menu',
            meta: {title: '菜单列表'},
            component: () => import('@/views/system/MenuList.vue'),
        },
        {
            name: "role",
            path: '/system/role',
            meta: {title: '角色列表'},
            component: () => import('@/views/system/RoleList.vue'),
        }, {
            name: "user",
            path: '/system/user',
            meta: {title: '用户列表'},
            component: () => import('@/views/system/UserList.vue'),
        }, {
            name: "banner",
            path: '/index/banner',
            meta: {title: '用户列表'},
            component: () => import('@/views/banner/bannerList.vue'),
        }, {
            name: "lecturer",
            path: '/lecturerList',
            meta: {title: '教师列表'},
            component: () => import('@/views/course/lecturerList.vue'),
        }, {
            name: "onlineCourseList",
            path: '/onlineCourseList',
            meta: {title: '线上课程'},
            component: () => import('@/views/course/onlineCourseList.vue'),
        }, {
            name: "classifyList",
            path: '/classifyList',
            meta: {title: '课程类型'},
            component: () => import('@/views/course/classifyList.vue'),
        }, {
            name: "courseInfo",
            path: '/courseInfo',
            meta: {title: '线上课程详情'},
            component: () => import('@/views/course/courseInfo.vue'),
        }, {
            name: "configList",
            path: '/configList',
            meta: {title: '配置列表'},
            component: () => import('@/views/config/configList.vue'),
        }, {
            name: "catalogueList",
            path: '/catalogueList',
            meta: {title: '线上课程目录列表'},
            component: () => import('@/views/course/catalogueList.vue'),
        }, {
            name: "orderList",
            path: '/orderList',
            meta: {title: '订单列表'},
            component: () => import('@/views/order/orderList.vue'),
        }, {
            name: "fflineCourseList",
            path: '/fflineCourseList',
            meta: {title: '线下课程'},
            component: () => import('@/views/course/fflineCourseList.vue'),
        }, {
            name: "periods",
            path: '/periods',
            meta: {title: '线下课程期数'},
            component: () => import('@/views/course/periods.vue'),
        }, {
            name: "applyList",
            path: '/applyList',
            meta: {title: '线下报名列表'},
            component: () => import('@/views/course/applyList.vue'),
        }, {
            name: "goodsList",
            path: '/goodsList',
            meta: {title: '商品列表'},
            component: () => import('@/views/goods/goodsList.vue'),
        }, {
            name: "goodsInfo",
            path: '/goodsInfo',
            meta: {title: '商品详情'},
            component: () => import('@/views/goods/goodsInfo.vue'),
        }, {
            name: "orderInfo",
            path: '/orderInfo',
            meta: {title: '订单详情'},
            component: () => import('@/views/order/orderInfo.vue'),
        }, {
            name: "userList",
            path: '/userList',
            meta: {title: '终端用户列表'},
            component: () => import('@/views/user/userList.vue'),
        }, {
            name: "userApplyList",
            path: '/userApplyList',
            meta: {title: '用户申请列表'},
            component: () => import('@/views/user/userApplyList.vue'),
        }, {
            name: "withdrawList",
            path: '/withdrawList',
            meta: {title: '提现列表'},
            component: () => import('@/views/withdraw/withdrawList.vue'),
        }, {
            name: "posterList",
            path: '/posterList',
            meta: {title: '海报列表'},
            component: () => import('@/views/poster/posterList.vue'),
        }, {
            name: "liveList",
            path: '/liveList',
            meta: {title: '直播列表'},
            component: () => import('@/views/live/liveList.vue'),
        }, {
            name: "anchorList",
            path: '/anchorList',
            meta: {title: '主播列表'},
            component: () => import('@/views/live/anchorList.vue'),
        }, {
            name: "live",
            path: '/live',
            meta: {title: '开始直播'},
            component: () => import('@/views/live/index.vue'),
        }, {
            name: "userComm",
            path: '/userComm',
            meta: {title: '用户分佣'},
            component: () => import('@/views/user/userComm.vue'),
        }, {
            name: "liveReward",
            path: '/liveReward',
            meta: {title: '打赏列表'},
            component: () => import('@/views/live/rewardList.vue'),
        }, {
            name: "liveGift",
            path: '/liveGift',
            meta: {title: '礼物管理'},
            component: () => import('@/views/live/giftList.vue'),
        }, {
            name: "liveLockBack",
            path: '/liveLockBack',
            meta: {title: '回放管理'},
            component: () => import('@/views/live/lockBackList.vue'),
        }
    ]
}]

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    NProgress.start()
    if (to.path === '/login') {
        next()
    } else {
        axios.get('/api/loginStatus').then(response => {
            if (response.data.code === 200) {
                next()
            } else {
                next('/login')
            }
        }).catch(error => {
            next('/login')
        })
    }
})


router.afterEach(() => {
    NProgress.done()
})

export default router
