<template>
  <div class="container">
    <!-- 不是首页的时候显示 动态面包屑-->
    <template v-if="breadList[1].path != '/index'">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
            v-for="item in breadList"
            :key="item.path"
            :to="{ path: item.path }"
        >{{ item.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [], // 路由集合
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    isHome(route) {
      return route.name === "home";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      //如果不是首页
      if (!this.isHome(matched[0])) {
        matched = [{path: "/home", meta: {title: "首页"}}].concat(matched);
      }
      this.breadList = matched;
    },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>

<style lang="less" scoped>
.el-breadcrumb {
  font-size: 14px !important;
}

/deep/ .is-link {
  color: #303133;
  font-weight: 400;
}

/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #97a8be !important;
}
</style>