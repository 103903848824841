<template>
  <div class="container">
    <el-container>
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="username layout">
          <img class="logo-img" src="../assets/logo.png" alt="">
          <span v-show="!isCollapse">青追国际-后台管理</span>
        </div>
        <el-menu :collapse-transition="false" :collapse="isCollapse" :default-active="$route.path"
                 background-color="#304156" text-color="#fff" active-text-color="#ffd04b" unique-opened router>
          <el-menu-item index="/index">
            <i class="el-icon-house"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <!-- 一级导航 -->
          <el-submenu :index="item.id+''" v-for="item in menuList" :key="item.id">
            <template slot="title">
              <i :class="item.icon || 'el-icon-location'"></i>
              <span>{{ item.name }}</span>
            </template>
            <!-- 二级导航 -->
            <el-menu-item @click="handlerTab(subItem)" :index="subItem.path" v-for="subItem in item.children"
                          :key="subItem.id">
              <template slot="title">
                <i :class="subItem.icon || 'el-icon-location'"></i>
                <span>{{ subItem.name }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header-top layout_sb">
            <div class="layout">
              <span @click="isCollapse = !isCollapse" class="el-icon-s-fold"></span>
              <breadcrumbList></breadcrumbList>
            </div>
            <el-dropdown placement="bottom-start" @command="handlerCommand">
              <div class="layout" style="margin-top:30px;">
                <span>{{ userinfo.userName || '青追国际' }}</span>
                <span class="el-icon-caret-bottom"></span>
                <img src="https://img0.baidu.com/it/u=2074464007,2669255982&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=736"
                     class="user-img" alt="">
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="amend">
                  <span class="el-icon-lock" style="margin-right:10px;"></span><span>修改密码</span>
                </el-dropdown-item>
                <el-dropdown-item command="quit">
                  <span class="el-icon-switch-button" style="margin-right:10px;"></span><span>退出登录</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="tab-list">
            <div @click="changeTag(item)" class="tab-item layout" :class="{'tab-item-active':$route.path == item.path}"
                 v-for="(item,index) in tags" :key="item.index">
              {{ item.name }}
              <span @click.stop="closeTag(item,index)" v-if="index != 0" class="el-icon-close"></span>
            </div>
          </div>
        </el-header>
        <el-main>
          <div style="flex:1;">
            <transition name="fade-transform" mode="out-in">
              <keep-alive>
                <router-view/>
              </keep-alive>
            </transition>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 修改密码弹出框 -->

    <el-dialog title="修改密码" :visible.sync="showAmendPassword" width="30%" :before-close="amenPasswordClose">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
        <el-form-item label="原密码" prop="password">
          <el-input v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="confirmPassowrd">
          <el-input v-model="ruleForm.confirmPassowrd"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="amenPasswordClose">取 消</el-button>
        <el-button type="primary" @click="dialogVisible">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {ListMenu} from "@/apis/index.js";
import {ClearLogin, setPassword} from "@/apis/login";
import {mapState} from "vuex";
import breadcrumbList from "@/components/breadcrumb/breadcrumbList.vue";

export default {
  components: {breadcrumbList},
  data() {
    return {
      menuList: [],
      isCollapse: false,
      showAmendPassword: false,
      ruleForm: {
        password: '',
        confirmPassowrd: '',
      },
      rules: {
        password: [
          {required: true, message: '请输入原密码', trigger: ['blur', 'change']},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: ['blur', 'change'],},
        ],
        confirmPassowrd: [
          {required: true, message: '请输入新密码', trigger: ['blur', 'change']},
          {min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: ['blur', 'change'],},
        ]
      },
      userinfo: {},
    };
  },
  created() {
    this.getLeftMenuData();
    this.getUserInfo()
  },
  computed: {
    ...mapState({
      tags: (state) => state.tabMenu.menuList,
    }),
  },
  methods: {
    //  获取登录信息
    getUserInfo() {
      const userinfo = window.sessionStorage.getItem('userinfo')
      if (userinfo) {
        this.userinfo = JSON.parse(userinfo)
      }
    },
    // 确认修改密码
    dialogVisible() {
      this.$refs.ruleForm.validate(async value => {
        if (!value) return
        const res = await setPassword(this.ruleForm)
        if (res.code === 200) {
          this.$message({message: res.msg, type: "success"});
          this.$router.push("/login");
          this.$store.commit("initMenu");
        }
      })
    },
    amenPasswordClose() {
      this.showAmendPassword = false
      this.$refs.ruleForm.resetFields();
    },
    /**
     * 点击菜单项
     * item = quit 退出登录 amend 修改密码
     */
    handlerCommand(item) {
      item == 'quit' ? this.quitLogin() : this.showAmendPassword = true
    },
    // 获取左侧导航数据
    async getLeftMenuData() {
      const res = await ListMenu();
      this.menuList = res.data;
    },
    // 退出登录
    async quitLogin() {
      const res = await ClearLogin();
      this.$store.commit("initMenu");
      this.$message({message: res.msg, type: "success"});
      this.$router.push("/login");
    },
    // 点击左侧的导航
    handlerTab(item) {
      this.$store.commit("changeMenu", item);
    },
    // 点击切换路由
    changeTag(item) {
      this.$router.push({path: item.path})
    },
    // 删除动态导航
    closeTag(item, index) {
      const length = this.tags.length - 1;
      // 如果是删除最后一个 就跳转到前面一个 否则就是跳转到最后一个
      if (index === length) {
        this.$router.push({path: this.tags[length - 1].path})
      } else {
        this.$router.push({path: this.tags[length].path})
      }
      this.$store.commit("removeMenu", item);
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  .el-container.is-vertical {
    height: 100vh;
  }

  .el-aside {
    background: #304156;

    .username {
      justify-content: space-around;
      height: 50px;
      color: #fff;
      font-size: 16px;
    }

    .logo-img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
    }

    .el-menu {
      border-right: none;
    }
  }

  /deep/ .el-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;

    .el-card {
      margin: 20px;
    }
  }

  .el-header {
    background: #ffffff;
    height: 84px !important;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    z-index: 9;

    .header-top {
      display: flex;
      align-items: center;
      height: 50px;

      .user-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-left: 20px;
      }
    }

    .tab-list {
      display: flex;

      .tab-item {
        font-size: 12px;
        color: #495060;
        height: 26px;
        border: 1px solid #d8dce5;
        background: #fff;
        padding: 0 8px;
        margin-left: 5px;
        margin-top: 4px;
        cursor: pointer;

        .el-icon-close {
          -webkit-text-size-adjust: none;
          margin-left: 6px;
        }
      }

      .el-icon-close:hover {
        padding: 2px;
        border-radius: 50%;
        color: #fff;
        background: #b4bccc;
      }

      .tab-item-active {
        background: #42b983;
        color: #fff;
      }
    }
  }

  .header-top .el-icon-s-fold {
    font-size: 24px;
    color: #686767;
    margin-right: 30px;
  }
}
</style>